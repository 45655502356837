import { createWebHashHistory, createRouter } from 'vue-router';
import HomePage from './components/HomePage';
import AcaraPage from './components/AcaraPage';
import AnggotaPage from './components/AnggotaPage';
import DetailAnggotaPage from './components/DetailAnggotaPage';
import DetailSubAnggotaPage from './components/DetailSubAnggotaPage';
// tambahkan beberapa component

const routes = [
  { path: '/', component: HomePage },
  { path: '/home', component: HomePage },
  { path: '/acara', component: AcaraPage },
  { path: '/anggota', component: AnggotaPage },
  { path: '/detail-anggota/:code', component: DetailAnggotaPage },
  { path: '/sub-detail-anggota/:name', component: DetailSubAnggotaPage },
	// perbanyak bagian ini
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;