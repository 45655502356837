<template>
    <main-layout>
        <div id="appCapsule">
            <!-- Wallet Card -->
            <div class="section mt-2">
                <div class="section-title">Dari Keluarga <strong class="text-primary">{{ parent }}</strong></div>
                <div class="transactions">
                    <!-- item -->
                    <router-link v-for="(item, index) in children" :key="index" :to="'/sub-detail-anggota/'+item.name" class="item">
                        <div class="detail">
                            <img src="assets/img/family.jpg" alt="img" class="image-block imaged w48">
                            <div>
                                <strong>{{ item.name }}</strong>
                                <p v-if="item.partner">Menikah dengan {{ item.partner }}</p>
                            </div>
                        </div>
                        <div class="right">
                            <div v-if="item.count_children" class="price text-success">{{ item.count_children }}</div>
                        </div>
                    </router-link>
                    <!-- * item -->
                </div>
            </div>
        </div>
    </main-layout>
</template>

<script>
import MainLayout from "../layouts/MainLayout.vue";
import axios from "axios";
export default {
    name: "AcaraPage",
    components: {
        MainLayout
    },
    data() {
        return {
            items: [], // Initialize an empty array to store fetched items
            parent : "",
            code : "",
            children : []
        };
    },
    created() {
        this.code = this.$route.params.code;
    },
    methods: {
        fetchData() {
            axios.get('https://api-bani.dewadev.id?v=1')
                .then(response => {
                    const dataWithCode = this.getDataByCode(this.code, response.data.data);
                    this.items = dataWithCode;
                    this.parent = dataWithCode.parent;
                    this.children = this.items.children;
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        },

        logout() {
            console.log('aa');
        },

        getDataByCode(code, jsonData) {
            for (const item of jsonData) {
                if (item.code === code) {
                    return item;
                }
            }
            return null; // Return null if code not found
        }
    },
    mounted() {
        // Call fetchData method when the component is mounted
        this.fetchData();
    }
}
</script>