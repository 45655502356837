<template>
    <main-layout>
        <div id="appCapsule">
            <!-- Wallet Card -->

            <div class="section">
                <div class="row mt-2">
                    <div class="col-6">
                        <div class="stat-box">
                            <div class="title">Anggota Keluarga</div>
                            <div class="value text-success">38</div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="stat-box">
                            <div class="title">Acara Ke</div>
                            <div class="value text-danger">5</div>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-6">
                        <div class="stat-box">
                            <div class="title">Tempat selanjutnya</div>
                            <div class="value">Bapak Jalil</div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="stat-box">
                            <div class="title">Pada</div>
                            <div class="value">4 April 2024</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main-layout>
</template>

<script>
import MainLayout from "../layouts/MainLayout.vue";
export default {
    name: "AcaraPage",
    components: {
        MainLayout
    },
    methods: {
        logout() {
            console.log('aa');
        }
    },
}
</script>