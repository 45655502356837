import { createApp } from 'vue';
import App from './App.vue';
import router from './route.js';
import AllIosIcon from 'vue-ionicons/dist/ionicons-ios.js'

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

// tambahkan style bila perlu
import '/public/assets/css/style.css';


// import '/public/assets/js/base.js';
  
createApp(App).use(router).use(AllIosIcon).mount('#app');