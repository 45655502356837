<template>
    <main-layout>
        <div id="appCapsule">
            <!-- Wallet Card -->
            <div class="section mt-2">
                <div class="section-title">Dari Keluarga <strong class="text-primary">{{ from_family }}</strong></div>
                <div class="transactions">
                    <!-- item -->
                    <router-link v-for="(item, index) in children" :key="index" :to="'/sub-detail-anggota/' + item.name"
                        class="item">
                        <div class="detail" v-if="item.name">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" style="margin-right: 10px;" height="1.5em" fill="currentColor"
                                class="bi bi-person-fill" viewBox="0 0 16 16">
                                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                            </svg>
                            <div>
                                <strong>{{ item.name }}</strong>
                                <p v-if="item.partner">Menikah dengan {{ item.partner }}</p>
                            </div>
                        </div>
                        <div class="right">
                            <div v-if="item.count_children" class="price text-success">{{ item.count_children }}</div>
                        </div>
                    </router-link>
                    <!-- * item -->
                </div>
            </div>
        </div>
    </main-layout>
</template>

<script>
import MainLayout from "../layouts/MainLayout.vue";
import axios from "axios";
export default {
    name: "AcaraPage",
    components: {
        MainLayout
    },
    data() {
        return {
            items: [], // Initialize an empty array to store fetched items
            children: [],
            name: "",
            from_family : ""
        };
    },
    created() {
        this.name = this.$route.params.name;
    },
    methods: {
        fetchData() {
            axios.get('https://api-bani.dewadev.id?v=1')
                .then(response => {
                    const parentData = this.searchParentByChildName(this.name, response.data.data);

                    if (parentData) {
                        this.children = parentData;
                        console.log("Parent data found:", parentData);
                    } else {
                        console.log("Parent data not found.");
                    }
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        },

        logout() {
            console.log('aa');
        },

        // Function to search for parent data by child name
        searchParentByChildName(childName, jsonData) {
            for (const parent of jsonData) {
                for (const child of parent.children) {
                    if (child.name === childName) {
                        this.from_family = child.name;
                        return child.children;
                    }
                }
            }
            return null; // Return null if not found
        }

    },
    mounted() {
        // Call fetchData method when the component is mounted
        this.fetchData();
    }
}
</script>